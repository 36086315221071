

$('input[name="ticket_type"]').change(function () {
    let additionalFieldsContainer = $('#additional-ticket-fields');
    switch (this.value) {
        case 'adult':
            additionalFieldsContainer.find('> div').not('.group-code').slideUp();
            additionalFieldsContainer.find('input').val('');
            additionalFieldsContainer.slideDown()
            additionalFieldsContainer.find('.group-code').slideDown();
            break;
        case 'student':
            additionalFieldsContainer.find('> div').not('.student-number').slideUp();
            additionalFieldsContainer.find('input').val('');
            additionalFieldsContainer.slideDown()
            additionalFieldsContainer.find('.student-number').slideDown();
            break;
        case 'youngster':
            additionalFieldsContainer.slideUp();
            additionalFieldsContainer.find('input').val('');
            additionalFieldsContainer.find('> div').slideUp();
            break;
        case 'performer':
            additionalFieldsContainer.find('> div').not('.performer-code').slideUp();
            additionalFieldsContainer.find('input').val('');
            additionalFieldsContainer.slideDown()
            additionalFieldsContainer.find('.performer-code').slideDown();
            break;
    }
});

